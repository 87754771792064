body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'carton';
  src: local('carton'), url(./fonts/Carton_Six.ttf) format('truetype');
}

@font-face {
  font-family: 'conthrax';
  src: local('conthrax'), url(./fonts/conthrax-sb.otf) format('truetype');
}

.login-grid-2{
  min-height: 110px;
}

@media only screen and (min-width: 900px) {
  .login-grid-2{
    min-height: 110px;
    border-radius: 0px 8px 8px 0px;
  }
  .img-orange-login{
    border-radius: 0px 8px 8px 0px;
  }
}

@media only screen and (max-width: 900px) {
  .login-grid-2{
    border-radius: 0px 0px 8px 8px;
  }
  .img-orange-login{
    border-radius: 0px 0px 8px 8px;
  }
}

.scrollStyle::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;

}

.scrollStyle::-webkit-scrollbar-thumb {
  border: 3px solid #cdcdcd;
  border-radius: 50px;
}

.scrollBloque::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;

}

.scrollBloque::-webkit-scrollbar-thumb {
  border: 3px solid #cdcdcd;
  border-radius: 50px;
}


.loader-5-5-wh {
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 3px;
  background: 
    radial-gradient(farthest-side, white 95%,#0000) 50% 0/5px 5px no-repeat,
    radial-gradient(farthest-side,#0000 calc(100% - 3px), white calc(100% - 3px)) content-box;
  animation: l6 2s infinite ;
}
@keyframes l6 {to{transform: rotate(1turn)}}